<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17 13.4V16.4C17 20.4 15.4 22 11.4 22H7.6C3.6 22 2 20.4 2 16.4V12.6C2 8.6 3.6 7 7.6 7H10.6"
      stroke="url(#paint0_linear_13706_66646)"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.9996 13.4H13.7996C11.3996 13.4 10.5996 12.6 10.5996 10.2V7L16.9996 13.4Z"
      stroke="url(#paint1_linear_13706_66646)"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.5996 2H15.5996"
      stroke="url(#paint2_linear_13706_66646)"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7 5C7 3.34 8.34 2 10 2H12.62"
      stroke="url(#paint3_linear_13706_66646)"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.9995 8V14.19C21.9995 15.74 20.7395 17 19.1895 17"
      stroke="url(#paint4_linear_13706_66646)"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22 8H19C16.75 8 16 7.25 16 5V2L22 8Z"
      stroke="url(#paint5_linear_13706_66646)"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_13706_66646"
        x1="17"
        y1="14.5949"
        x2="2"
        y2="14.5949"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F0899E" />
        <stop offset="0.244792" stop-color="#9617D2" />
        <stop offset="0.510417" stop-color="#4E0FFF" />
        <stop offset="0.75" stop-color="#2C60FD" />
        <stop offset="1" stop-color="#30F0F6" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_13706_66646"
        x1="16.9996"
        y1="10.2405"
        x2="10.5996"
        y2="10.2405"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F0899E" />
        <stop offset="0.244792" stop-color="#9617D2" />
        <stop offset="0.510417" stop-color="#4E0FFF" />
        <stop offset="0.75" stop-color="#2C60FD" />
        <stop offset="1" stop-color="#30F0F6" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_13706_66646"
        x1="15.5996"
        y1="2.50633"
        x2="11.5996"
        y2="2.50633"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F0899E" />
        <stop offset="0.244792" stop-color="#9617D2" />
        <stop offset="0.510417" stop-color="#4E0FFF" />
        <stop offset="0.75" stop-color="#2C60FD" />
        <stop offset="1" stop-color="#30F0F6" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_13706_66646"
        x1="12.62"
        y1="3.51899"
        x2="7"
        y2="3.51899"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F0899E" />
        <stop offset="0.244792" stop-color="#9617D2" />
        <stop offset="0.510417" stop-color="#4E0FFF" />
        <stop offset="0.75" stop-color="#2C60FD" />
        <stop offset="1" stop-color="#30F0F6" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_13706_66646"
        x1="21.9995"
        y1="12.557"
        x2="19.1895"
        y2="12.557"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F0899E" />
        <stop offset="0.244792" stop-color="#9617D2" />
        <stop offset="0.510417" stop-color="#4E0FFF" />
        <stop offset="0.75" stop-color="#2C60FD" />
        <stop offset="1" stop-color="#30F0F6" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_13706_66646"
        x1="22"
        y1="5.03798"
        x2="16"
        y2="5.03798"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F0899E" />
        <stop offset="0.244792" stop-color="#9617D2" />
        <stop offset="0.510417" stop-color="#4E0FFF" />
        <stop offset="0.75" stop-color="#2C60FD" />
        <stop offset="1" stop-color="#30F0F6" />
      </linearGradient>
    </defs>
  </svg>
</template>
